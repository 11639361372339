.cc-btn{
  border-radius: 9999px;
  padding: 0.5rem 1.75rem;

  &.cc-deny{
    background-color: var(--palette__paragraph-200) !important;
  }
}

.rounded-modal{
  border-radius: 20px;
}
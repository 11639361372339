form {
  input,
  textarea,
  select,
  input[type="checkbox"] + div,
  input[type="radio"] + div {
    &.border-palette-paragraph-300 {
      @apply border-palette-accent;
    }
  }

  input,
  select {
    &.error {
      @apply bg-red-50 rounded-b-none;
    }
  }

  .error-message {
    @apply bg-white mt-0 border-red-300 border border-t-0 rounded-t-none text-red-700 rounded-input-md px-3 py-2;
  }

  button[type="submit"] {
    @apply flex ml-auto;
  }
}
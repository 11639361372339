.prose,
.break-long-anchors {
  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.prose ul{
  li ul{
    padding-left: 0;
    li{
      list-style-type: none;
      padding-left: 2rem;
      text-indent: -2rem;
    }
    li:before {
      content: ">";
      padding-right: 5px;
    }
  }
  
}
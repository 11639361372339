dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: grid;
    background: transparent;
    padding: 1.5rem;
    margin: 0 auto;
    max-height: 100vh;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;

    >* {
        overflow: auto;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
    }
}

dialog:not([open]) {
    display: none;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.1);
    background: var(--dialog-backdrop, rgba(0, 0, 0, 0.1));
}

dialog+.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    background: var(--dialog-backdrop, rgba(0, 0, 0, 0.1));
}

._dialog_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
// // Firefox Only: Sticky <th> border hack
// @-moz-document url-prefix() { 
//     .dosage-table table th {
//         box-shadow: 0 0 0 1px var(--colour-deep-sea-blue-rgb);
//     }
// }
.dosage-table {
    $this: '.dosage-table';
    
    &__outer-wrapper {
        @apply border border-[var(--colour-deep-sea-blue-rgb)] rounded-card-md overflow-hidden text-[var(--colour-grey-rgb)];
    }

    &__inner-wrapper {
        @apply min-w-full;
        overflow: auto;
        max-height: 90vh;
        
        table {
            border-collapse: separate; border-spacing: 0;
            @apply whitespace-nowrap text-center min-w-full;
    
            th {
                @apply font-bold bg-[var(--colour-porcelain-rgb)];
            }
            td,
            th {
                @apply border-r border-b border-[var(--colour-deep-sea-blue-rgb)] py-3 px-4 border-t-0 last:border-r-0;
            }
    
            td {
                @apply bg-white;
            }
    
            thead {
                td,
                th {
                    @apply border-t-0;
                }
            }
            tbody tr:last-child {
                td,
                th {
                    @apply border-b-0;
                }
            }
    
            thead {
                th {
                    @apply py-4 px-6;
    
                    position: sticky;
                    top: 0;
    
                    &:first-child {
                        left: 0;
                        z-index: 2;
                    }
                    &:not(:first-child) {
                        z-index: 1;
                    }
                }
            }
            tbody  {
                th {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }
                tr:hover{
                    td{
                        background-color: var(--colour-porcelain-rgb);
                    }
                    td:hover{
                        font-weight: bold;
                    }
                }
            }
        }
    }

}
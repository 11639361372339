.page {
    &__main {
        @apply @container;
    }
    .yuzu-layout-root {
        min-height: 100vh;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr) auto;
        grid-template-columns: 100%;

        >a,
        >a+* {
            grid-row: 1;
            grid-column: 1;
        }
    }
}
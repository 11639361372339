.site-header-default {
  $this: '.site-header-default';

  &__action-buttons {
    .button-link-list {
      a {
        @apply text-sm;

        &:not(:last-child) {
          @apply pr-3 border-r border-palette-link-200;
        }  
      }          
    }
  }  
}

#compliance-header-links{
  .button-link-list {
    a {
      @apply text-sm; 
    }          
  }
  .gap-3{
    gap: 0.25rem;
  }
}
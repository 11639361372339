.theme-video .onward-journey__image {
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ccircle cx='40' cy='40' r='40' style='fill:%23fff;opacity:.8'/%3E%3Cpath d='M30 56c0 1.1.7 1.5 1.7 1l24.8-16c1-.5 1-1.5 0-2L31.7 23c-1-.5-1.7-.1-1.7 1v32Z' style='fill:%23004f80'/%3E%3C/svg%3E");
    background-size: cover;
    content: '';
    display: block;
    padding-bottom: 20%;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
  }
}
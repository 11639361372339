@mixin colours {
  /* Colours must be supplied in rgb format for Tailwind opacity utility classes to work (uses rgba) */
  --colour-deep-sea-blue-rgb: rgb(0, 79, 128);
  --colour-deep-sea-blue: 0, 79, 128;

  --colour-mid-blue-rgb: rgb(29, 113, 178);
  --colour-mid-blue: 29, 113, 178;

  --colour-light-blue-rgb: rgb(219, 245, 255);
  --colour-light-blue: 219, 245, 255;

  --colour-light-blue-dark-rgb: rgb(155, 199, 233);
  --colour-light-blue-dark: 155, 199, 233;
  --colour-day-sky-blue-rgb: rgb(129, 206, 243);
  --colour-day-sky-blue: 129, 206, 243;


  --colour-white-rgb: rgb(255, 255, 255);
  --colour-white: 255, 255, 255;

  --colour-grey-rgb: rgb(51, 51, 51);
  --colour-grey: 51, 51, 51;

  --colour-orange-rgb: rgb(232, 125, 28);
  --colour-orange: 232, 125, 28;

  --colour-orange-dark-rgb: rgb(232, 74, 0);
  --colour-orange-dark: 232, 74, 0;

  /* Icon list blues */
  --colour-tropical-blue-rgb: rgb(193, 226, 246);
  --colour-tropical-blue: 193, 226, 246;

  --colour-porcelain-rgb: rgb(235, 241, 245);
  --colour-porcelain: 235, 241, 245;

  --colour-rich-electric-blue-rgb: rgb(0, 153, 221);
  --colour-rich-electric-blue: 0, 153, 221;

  // --test-colour: rgb(0, 79, 128);
}

:root {
  @include colours;
}

::backdrop {
  @include colours;
}